import { uuid } from '../../types/common-helper';
import {
  GameDocument,
  MediaOverview,
  Overview
} from '../../types/game-document';
import { MapEntity, ResourceEntity } from '../../types/game-document/';
import {
  DesignerSettings,
  InGameSettings,
  PreGameSettings
} from '../../types/game-document/settings';
import { AddResourceEntityAsync, GetResourcesByIds } from './resources';
import { AddMapEntityAsync } from './assets';
import cloneDeep from 'lodash.clonedeep';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM_LEVEL
} from '../../constants/coordinate';

/**
 * Creates a Valid Default Game Document.
 */
export const GenerateGameDocument = async () => {
  // Initialise a new blank game document.
  let newGameDocument = GetBlankGameDocument();

  // Create the game overview resources.
  // prettier-ignore
  let overviewResources: ResourceEntity[] = [
    { id: newGameDocument.overview!.titleResId!, name: 'Title', description: 'The game\'s title', value: '', type: 'text' },
    { id: newGameDocument.overview!.shortDescriptionResId!, name: 'Short description', description: 'The game\'s short description', value: '', type: 'text' },
    { id: newGameDocument.overview!.longDescriptionResId!, name: 'Long description', description: 'The game\'s long description', value: '', type: 'text' },
    { id: newGameDocument.overview!.recommendedNumberOfPlayersResId!, name: 'Recommended number of players', description: 'The game\'s recommended number of players', value: '', type: 'text' },
    { id: newGameDocument.overview!.recommendedDurationResId!, name: 'Recommended duration', description: 'The game\'s recommended duration', value: '', type: 'text' },
    { id: newGameDocument.overview!.learningOutcomeResId!, name: 'Learning outcome', description: 'The game\'s learning outcome', value: '', type: 'text' },
  ];

  // Generate the blank resources for the overviews.
  for (const res of overviewResources) {
    newGameDocument = await AddResourceEntityAsync(newGameDocument, res);
  }

  // Create a new blank map.
  // TODO: Default to current users location GPS map.
  let worldMap: MapEntity = GenerateDefaultWorldMap(
    newGameDocument.rules.worldMap.mapAssId
  );

  // Add the new blank map.
  newGameDocument = await AddMapEntityAsync(newGameDocument, worldMap);

  const designerSettingResource: ResourceEntity[] = [
    {
      id: uuid(),
      name: 'Designer Setting - default available task icon',
      description: "The game's default available task icon",
      value: 'https://cdn.catalystglobal.games/resources/map-task.png',
      type: 'text'
    },
    {
      id: uuid(),
      name: 'Designer Setting - default completed task icon',
      description: "The game's default completed task icon",
      value:
        'https://cdn.catalystglobal.games/resources/map-task--complete.png',
      type: 'text'
    }
  ];

  for (const res of designerSettingResource) {
    newGameDocument = await AddResourceEntityAsync(newGameDocument, res);
  }

  newGameDocument.settings.designer.defaultTaskAvailableIconResId =
    designerSettingResource[0].id;
  newGameDocument.settings.designer.defaultTaskCompletedIconResId =
    designerSettingResource[1].id;

  return newGameDocument;
};

export const GenerateDefaultWorldMap = (
  mapAssId: string,
  latitude?: number,
  longitude?: number,
  zoomLevel?: number
): MapEntity => {
  const worldMap: MapEntity = {
    id: mapAssId,
    name: 'world-map',
    description: "The game's world map",
    type: 'openStreetMap',
    latitude: latitude ?? DEFAULT_LATITUDE,
    longitude: longitude ?? DEFAULT_LONGITUDE,
    zoomLevel: zoomLevel ?? DEFAULT_ZOOM_LEVEL,
    url: `https://www.openstreetmap.org/#map=${
      zoomLevel ?? DEFAULT_ZOOM_LEVEL
    }/${latitude ?? DEFAULT_LATITUDE}/${longitude ?? DEFAULT_LONGITUDE}`
  };

  return worldMap;
};

/**
 * Creates a new blank game document.
 * @remarks
 * WARNING: This is not a valid game document. You will need to generate resources!
 */
export const GetBlankGameDocument = (): GameDocument => {
  return {
    name: '',
    version: '0.0.0',
    description: '',
    keywords: [],
    homepage: '',
    license: '',
    author: '',
    assets: {
      maps: [],
      items: [],
      titles: [],
      timers: [],
      assessments: [],
      tasks: [],
      areas: [],
      zones: [],
      taskContents: [],
      routes: []
    },
    overview: GetBlankGameDocumentOverview(),
    resources: [],
    rules: {
      worldMap: {
        events: {
          onEnterMap: {},
          onExitMap: {}
        },
        timerAssId: '',
        imageResId: '',
        mapAssId: uuid(),
        tasks: [],
        zones: [],
        timers: []
      }
    },
    settings: {
      designer: GetDefaultDesignerSettings(),
      preGame: GetDefaultPreGameSettings(),
      inGame: GetDefaultInGameSettings()
    }
  };
};

/**
 * Creates a blank Game Document Overview Section.
 * @remarks
 * You should also generate the associated resources into the game document.
 */
export const GetBlankGameDocumentOverview = (): Overview => {
  return {
    logoResId: '',
    headerResId: '',
    titleResId: uuid(),
    shortDescriptionResId: uuid(),
    longDescriptionResId: uuid(),
    recommendedNumberOfPlayersResId: uuid(),
    recommendedDurationResId: uuid(),
    playersPerTeam: 0,
    languages: '',
    medias: [],
    learningOutcomeResId: uuid()
  };
};

/**
 * Creates the default designer settings for the game document.
 */
export const GetDefaultDesignerSettings = (): DesignerSettings => {
  return {
    taskCompletionVisibility: true,
    defaultTaskAvailableIconResId: '',
    defaultTaskCompletedIconResId: '',
    defaultFeedbackAllCorrectIconResId: '',
    defaultFeedbackAllWrongIconResId: '',
    defaultAllWrongFeedbackMessageResId: '',
    defaultAllCorrectFeedbackMessageResId: ''
  };
};

/**
 * Creates the default pre game settings for the game document.
 */
export const GetDefaultPreGameSettings = (): PreGameSettings => {
  return {
    showPreGameLobby: true,
    showPreGameTeams: true,
    showPreGameCreateTeams: true,
    showPreGameJoinLeaveTeams: true,
    showPreGameInventory: true,
    showPreGameRoles: true,
    showPreGameChat: true,
    isPreGameTeamRequired: true
  };
};

/**
 * Creates the default in game settings for the game document.
 */
export const GetDefaultInGameSettings = (): InGameSettings => {
  return {
    showInGameInfo: true,
    showInGamePlayerTeamMapPositions: true,
    showInGameTeamChat: true,
    showInGameAllPlayerChat: true,
    showInGameLeaderboard: true,
    showInGameScoreboard: true,
    showInGamePlayerTeamScore: true,
    tasksRequireProximity: true,
    showTasknameVisibility: true,
    showTeamOrPlayerNameOnMap: true,
    showPlayerScoreOnMap: false,
    showPlayerDetailByClickOnMap: true
  };
};

export const AddExistingOverviewResources = (gameDocument: GameDocument) => {
  let resources: ResourceEntity[] = [];
  for (const res of (
    Object.keys(
      gameDocument?.overview!
    ) as (keyof typeof gameDocument.overview)[]
  ).filter((key: string) => key.toString().endsWith('ResId'))) {
    if (gameDocument?.overview) {
      let field: string = gameDocument.overview[res];

      if (field) {
        let resource: ResourceEntity[] = GetResourcesByIds(gameDocument, [
          field
        ]);

        if (resource.length > 0) {
          resources.push(cloneDeep(resource[0]));
        }
      }
    }
  }

  if (
    gameDocument?.overview?.medias &&
    gameDocument.overview.medias.length > 0
  ) {
    gameDocument.overview.medias.forEach((media: MediaOverview) => {
      let resource: ResourceEntity[] = GetResourcesByIds(gameDocument, [
        media?.value
      ]);

      if (resource.length > 0) {
        resources.push(cloneDeep(resource[0]));
      }
    });
  }

  return resources;
};
