import cloneDeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import { uuid } from '../../types/common-helper';
import { GameDocument, Resource } from '../../types/game-document';
import {
  ResourceEntity,
  ResourcePackEntity
} from '../../types/game-document/entities';
import { LanguageMapping } from '../../types/game-document/entities/resource-pack';
import { GetNextAssetNameAsync } from './assets';

/**
 * Add resource pack.
 * @param gameDocument - The Game Document to modify
 * @param name - Name of resource pack
 * @param isCopyOriginaltext - Copy value from original resource
 * @param displayLanguage - name of display language selected
 * @param displayLanguageUrl - blob file url of display language selected
 * @return The updated Game Document
 */
export const AddResourcePackAsync = async (
  gameDocument: GameDocument,
  name: string,
  isCopyOriginaltext?: boolean,
  displayLanguage?: string,
  displayLanguageUrl?: string
) => {
  let resourcePacks = cloneDeep(gameDocument.resourcePacks) ?? [];
  let resourcePackName = await GetNextAssetNameAsync(resourcePacks, name);

  let originalResources: Resource[] = cloneDeep(gameDocument?.resources) ?? [];

  let newResourcePack: ResourcePackEntity = {
    id: uuid(),
    description: '',
    name: resourcePackName,
    displayLanguage: displayLanguage,
    displayLanguageUrl: displayLanguageUrl,
    resources: []
  };

  originalResources.forEach((resource) => {
    if (!isCopyOriginaltext) {
      resource.value = '';
    }
    newResourcePack.resources!.push(resource as ResourceEntity);
  });

  resourcePacks.push(newResourcePack);

  return merge(gameDocument, {
    resourcePacks
  });
};

/**
 * Updates the resource pack's resource in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const UpdateResourcePackResourceAsync = async (
  gameDocument: GameDocument,
  name: string,
  resourceId: string,
  resource: ResourceEntity
) => {
  let resourcePacks = GetResourcePacks(gameDocument);

  let resourcePacksIndex = resourcePacks.findIndex((i) => i.name === name)!;

  if (resourcePacksIndex > -1) {
    let resourcePack = resourcePacks[resourcePacksIndex];

    let resourceIndex = resourcePack?.resources?.findIndex(
      (x) => x.id === resourceId
    )!;

    if (resourceIndex > -1) {
      resourcePacks[resourcePacksIndex].resources![resourceIndex] = resource;
    }
  }
  return merge(gameDocument, {
    resourcePacks
  });
};

/**
 * Updates the resources pack detail in the Game Document.
 * Not Update resources of resource pack.
 * @param gameDocument - The Game Document to modify
 * @param resourcePackId - The ID of the Resource pack to update
 * @param displayLanguage - name of display language selected
 * @param displayLanguageUrl - blob file url of display language selected
 * @return The updated Game Document
 */
export const UpdateResourcePackDetailAsync = async (
  gameDocument: GameDocument,
  name: string,
  resourcePackId: string,
  displayLanguage: string,
  displayLanguageUrl: string
) => {
  let resourcePacks = GetResourcePacks(gameDocument);

  let resourcePacksIndex = resourcePacks.findIndex(
    (i) => i.id === resourcePackId
  )!;

  if (resourcePacksIndex > -1) {
    let newResourcePack = resourcePacks[resourcePacksIndex];
    newResourcePack.name = name;
    newResourcePack.displayLanguage = displayLanguage;
    newResourcePack.displayLanguageUrl = displayLanguageUrl;

    resourcePacks[resourcePacksIndex] = newResourcePack;
  }
  return merge(gameDocument, {
    resourcePacks
  });
};

/**
 * Add the resource pack's resource in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const AddResourcePackResourceAsync = async (
  gameDocument: GameDocument,
  name: string,
  resource: ResourceEntity
) => {
  let resourcePacks = GetResourcePacks(gameDocument);

  let resourcePacksIndex = resourcePacks.findIndex((i) => i.name === name)!;

  if (resourcePacksIndex > -1) {
    resourcePacks[resourcePacksIndex].resources?.push(resource);

    return merge(gameDocument, {
      resourcePacks
    });
  }
};

/**
 * Retrieves all resource packs from the game document.
 * @param gameDocument - The Game Document to modify
 */
export const GetResourcePacks = (
  gameDocument: GameDocument
): ResourcePackEntity[] => {
  return (gameDocument.resourcePacks as ResourcePackEntity[]) ?? [];
};

/**
 * get resource pack by name.
 * @param gameDocument - The Game Document to modify
 * @param name - Name of resource pack
 * @return The Game Document
 */
export const GetResourcePackByName = (
  gameDocument: GameDocument,
  name: string
) => {
  const resources = cloneDeep(gameDocument?.resources);
  let newLanguageMapping: LanguageMapping[] = [];
  const selectedResourcePack = gameDocument?.resourcePacks?.find(
    (x) => x.name === name
  );

  resources?.forEach((resource) => {
    let selectedNewLanguage = selectedResourcePack?.resources?.find(
      (x) => x !== null && x.id === resource?.id
    );

    newLanguageMapping.push({
      id: resource?.id,
      type: resource.type,
      originalLanguage: resource?.value,
      newLanguage: selectedNewLanguage && selectedNewLanguage?.value,
      isEditing: true
    });
  });
  return newLanguageMapping;
};

/**
 * Get resource pack's resource by name.
 * @param gameDocument - The Game Document to modify
 * @param name - Name of resource pack
 * @param resourceId - Resource Id you want to search
 * @return The Resource pack's resource
 */
export const GetResourcePackResource = (
  gameDocument: GameDocument,
  name: string,
  resourceId: string
) => {
  const selectedResourcePack = gameDocument?.resourcePacks?.find(
    (x) => x.name === name
  );

  return selectedResourcePack?.resources?.find((x) => x.id === resourceId);
};
