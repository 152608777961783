import { GameDocumentContext } from '../../../contexts/game-document';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Toolbar from '../toolbar';
import {
  AddResourceAsync,
  UpdateGameDocState
} from '../../../utils/game-document';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridNoRecords
} from '@progress/kendo-react-grid';
import { LinkCell } from '../../../components/grid';
import { TaskContentEntity } from '../../../types/game-document/';
import { EntityEditor } from '../../../types/game-document/entity-editor';
import EditDeleteCloneCell from '../../../components/grid/edit-delete-clone-cell';
import {
  AddTaskContentAsync,
  CopyTaskContentAsync,
  DeleteTaskContentAsync,
  UpdateTaskContentAsync
} from '../../../utils/game-document/assets/task-contents';
import { TaskContentEditorWindow } from '../../../features/game-document/task-contents';
import {
  GridToolBar,
  GridToolbarDataStateChangeEvent
} from '../../../components/grid/grid-tool-bar';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { NoRecords } from '../../../components/grid/no-records';
import { DefaultGridSettings } from '../../../constants/grid-settings';
import { uuid } from '../../../types/common-helper';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useSessionStorage } from 'usehooks-ts';

const initialDataState: State = {
  sort: [{ field: 'name', dir: 'asc' }],
  ...DefaultGridSettings.initialDataState
};

const TaskContents = () => {
  const [state, setState] = useContext(GameDocumentContext);
  const [dataState, setDataState] = useSessionStorage<State>(
    'datastate-task-contents',
    initialDataState
  );
  const filteredTaskContent = state.gameDocument?.assets?.taskContents!.filter(
    (x) => x.id !== '-'
  );
  useEffect(() => {
    // Set the page task.
    document.title = `Task Contents - ${state.gameDocument?.name}`;
  }, [state]);

  const LinkedNameCell = (props: GridCellProps) => (
    <LinkCell to={props.dataItem['id'].toString()} {...props} />
  );

  const DeleteCell = (props: GridCellProps) => (
    <EditDeleteCloneCell
      onEditClick={() => onEditEntity(props.dataItem['id'])}
      onCloneClick={() => onCloneEntity(props.dataItem['id'])}
      onDeleteClick={() => onDeleteEntity(props.dataItem['id'])}
      {...props}
    />
  );

  const DescriptionCell = (props: GridCellProps) => {
    const descriptions = props.dataItem?.description;
    if (descriptions.length <= 250) {
      return <td role={'gridcell'}>{descriptions}</td>;
    }
    return (
      <td title={descriptions} role={'gridcell'}>
        {descriptions.slice(0, 250)}...
      </td>
    );
  };

  const FormCell = (props: GridCellProps) => {
    const isExistingForms = props.dataItem?.forms?.length > 0 ? 'Yes' : 'No';
    return <td role={'gridcell'}>{isExistingForms}</td>;
  };

  const [entityEditorValue, setEntityEditorValue] =
    useState<EntityEditor<TaskContentEntity>>();
  const [entityEditorIsVisible, setEntityEditorIsVisible] =
    useState<boolean>(false);
  const toggleEntityEditor = () => {
    setEntityEditorIsVisible(!entityEditorIsVisible);
  };

  const handleEntityEditorSubmit = async (
    editorEntity: EntityEditor<TaskContentEntity>
  ) => {
    if (editorEntity.isNew) {
      const titleGuid = uuid(); //titleResId
      const preMessageGuid = uuid(); //preMessageResId
      const contentGuid = uuid(); //contentResId
      const taskContentId = uuid();

      const addTitleResource = AddResourceAsync(
        state.gameDocument!,
        'Title',
        '',
        'text',
        '',
        titleGuid
      );
      const addContentResource = AddResourceAsync(
        state.gameDocument!,
        'Content',
        '',
        'text-md',
        '',
        contentGuid
      );
      const addPreMessageResource = AddResourceAsync(
        state.gameDocument!,
        'PreMessage',
        '',
        'text-md',
        '',
        preMessageGuid
      );
      Promise.all([
        addTitleResource,
        addContentResource,
        addPreMessageResource
      ]).then(() => {
        AddTaskContentAsync(
          state.gameDocument!,
          editorEntity.entity.name,
          editorEntity.entity.description,
          editorEntity.entity.bodyType!,
          titleGuid,
          preMessageGuid,
          contentGuid,
          taskContentId
        )
          .then((updatedGameDocument) => {
            setState((state) => UpdateGameDocState(state, updatedGameDocument));
            setEntityEditorIsVisible(false);
          })
          .finally(() => {
            window.location.replace(
              `/designer/${state.gameId}/assets/task-content/${taskContentId}`
            );
          });
      });
    } else {
      UpdateTaskContentAsync(
        state.gameDocument!,
        editorEntity.entity.id,
        editorEntity.entity
      ).then((updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
        setEntityEditorIsVisible(false);
      });
    }
  };

  const onAddEntity = () => {
    setEntityEditorValue({
      isNew: true,
      entity: { id: '', name: '', description: '' }
    });
    toggleEntityEditor();
  };

  const onEditEntity = (entityId: string) => {
    setEntityEditorValue({
      isNew: false,
      entity: state.gameDocument?.assets.taskContents?.find(
        (i) => i.id === entityId
      )!
    });
    toggleEntityEditor();
  };

  const onCloneEntity = (entityId: string) => {
    CopyTaskContentAsync(state?.gameDocument!, entityId).then((response) => {
      setState((state) => UpdateGameDocState(state, response));
    });
  };

  const onDeleteEntity = async (entityId: string) => {
    DeleteTaskContentAsync(state.gameDocument!, entityId).then(
      (updatedGameDocument) => {
        setState((state) => UpdateGameDocState(state, updatedGameDocument));
      }
    );
  };

  const gridExportRef = useRef<ExcelExport | null>(null);

  return (
    <>
      <Toolbar title={'Task Contents'}>
        <Button onClick={onAddEntity} themeColor={'primary'}>
          Add task content
        </Button>
        {entityEditorIsVisible && (
          <TaskContentEditorWindow
            toggleDialog={toggleEntityEditor}
            onSubmit={handleEntityEditorSubmit}
            onClose={toggleEntityEditor}
            editorEntity={entityEditorValue!}
            editorMode={'basic'}
          />
        )}
      </Toolbar>
      <div className={'pt-2'}>
        <GridToolBar
          searchPlaceholder={'Search task contents'}
          columnsToSearch={['name', 'description']}
          showCardMode={false}
          exportRef={gridExportRef}
          {...dataState}
          onDataStateChange={(e: GridToolbarDataStateChangeEvent) => {
            setDataState(e.dataState);
          }}
        />
        <Tooltip openDelay={300} position={'right'} anchorElement={'target'}>
          <ExcelExport
            data={filteredTaskContent ?? []}
            ref={gridExportRef}
            fileName={`${state.gameDocument?.name} task contents.xlsx`}>
            <Grid
              pageable={DefaultGridSettings.pagerSettings}
              sortable={true}
              className={'cg-grid3'}
              data={process(filteredTaskContent ?? [], dataState)}
              {...dataState}
              onDataStateChange={(e: GridDataStateChangeEvent) => {
                setDataState(e.dataState);
              }}>
              <Column field={'name'} title={'Name'} cell={LinkedNameCell} />
              <Column
                field={'description'}
                title={'Description'}
                cell={DescriptionCell}
              />
              <Column field={'bodyType'} title={'Body type'} width={150} />
              <Column
                field={'forms'}
                title={'Form'}
                cell={FormCell}
                width={150}
              />
              <Column cell={DeleteCell} />
              <GridNoRecords>
                <NoRecords />
              </GridNoRecords>
            </Grid>
          </ExcelExport>
        </Tooltip>
      </div>
    </>
  );
};

export default TaskContents;
