import { cloneDeep } from 'lodash';
import { uuid } from '../../types/common-helper';
import { GameDocument, Resource } from '../../types/game-document';
import { ResourceEntity } from '../../types/game-document/';
import { EntityBase } from '../../types/game-document/entities/entity-base';
import { ResourceType } from '../../types/game-document/resource-type';
import { MergeResources } from './assets';

/**
 * Checks to see if a Resources with the defined name already exists.
 * @param resources - The list of Resources to check
 * @param name - The Name of the Resource
 * @param id - The ID of the current Resource to ignore
 * @returns True if a Resource with the same name already exists.
 */
export const ResourceExistsAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceIndex = resources.findIndex(
    (i) => i.name === name && i.id !== id
  );
  return resourceIndex !== -1;
};

/**
 * Checks to see if a Resources with the defined id already exists.
 * @param resources - The list of Resources to check
 * @param resourceId - The ID of the Resource you want to check
 * @returns True if Resource with resourceId exist
 */
export const ResourceIdExistsAsync = async (
  resources: Array<ResourceEntity>,
  resourceId: string
): Promise<boolean> => {
  return (
    resources.findIndex(
      (resource: ResourceEntity) => resource.id === resourceId
    ) !== -1
  );
};

/**
 * Gets the next unique Resource name.
 * @param resources - The list of Resources to check
 * @param name - The Name of the Resource
 * @param id - The ID of the current Resource to ignore. I.e. for Edit.
 * @returns The next unique Resource name.
 */
export const GetNextResourceNameAsync = async (
  resources: Array<ResourceEntity>,
  name: string,
  id?: string
) => {
  let resourceExists = await ResourceExistsAsync(resources, name, id);
  let counter = 1;
  let nextResourceName = name;
  while (resourceExists) {
    nextResourceName = `${name}-${counter}`;
    resourceExists = await ResourceExistsAsync(resources, nextResourceName, id);
    counter += 1;
  }
  return nextResourceName;
};

/**
 Adds a new Resource to the Game document.
 @param gameDocument - The Game Document to check resources
 @param name - The Name of the new Resource
 @param description - The Description for the new Resource
 @param resourceType - The Type for the new Resource
 @param value - The value for the new Resource
 @param resourceId - The new ID for added resource
 @param size - The new Size when data is image
 @returns The updated Game Document
 */
export const AddResourceAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  resourceType: ResourceType,
  value: string,
  resourceId: string = uuid(),
  size?: number
) => {
  return AddResourceEntityAsync(gameDocument, {
    id: resourceId,
    name,
    description,
    type: resourceType,
    value,
    size
  });
};

/**
 * Adds a new Resource to the Game document.
 * @param gameDocument - The Game Document to check resources
 * @param entity - The Resource entity to add to the document
 * @returns The updated Game Document
 */
export const AddResourceEntityAsync = async (
  gameDocument: GameDocument,
  entity: ResourceEntity
) => {
  let resources = GetResources(gameDocument);
  let resourceName = await GetNextResourceNameAsync(resources, entity.name);
  resources.push({ ...entity, name: resourceName });
  return MergeResources(gameDocument, resources);
};

/**
 * Deletes the identified Resource from the Game Document.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the resource to delete
 * @returns The updated Game Document
 */
export const DeleteResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  if (resourceIndex < 0) {
    return gameDocument;
  }
  resources.splice(resourceIndex, 1);
  return MergeResources(gameDocument, resources);
};

/**
 * Updates the identified Resource in the Game Document.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 */
export const UpdateResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string,
  resource: ResourceEntity
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  resource.name = await GetNextResourceNameAsync(
    resources,
    resource.name,
    resource.id
  );
  resources[resourceIndex] = resource;
  return MergeResources(gameDocument, resources);
};

/**
 * Save the Resource in the Game Document it will check the resource is exist or not.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the Resource to update
 * @param resource - The updated Resource
 * @constructor
 */
export const SaveResourceAsync = async (
  gameDocument: GameDocument,
  resource: ResourceEntity
) => {
  const resourceExists = gameDocument?.resources?.find(
    (x) => x?.id === resource.id
  );
  if (resourceExists) {
    return await UpdateResourceAsync(gameDocument, resource.id, resource);
  }
  if (!resourceExists) {
    return await AddResourceAsync(
      gameDocument,
      resource.name,
      resource.description,
      resource.type as ResourceType,
      resource.value!,
      resource.id
    );
  }
};

/**
 * Create a copy of the Resource in the Game document.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the Resource to copy
 * @param copiedResourceId - The new ID of the copied Resource
 * @returns The updated Game Document
 */
export const CopyResourceAsync = async (
  gameDocument: GameDocument,
  resourceId: string,
  copiedResourceId: string = uuid(),
  keepValue: boolean = true
) => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  let resourceCopy = cloneDeep({ ...resources[resourceIndex] });
  resourceCopy.id = copiedResourceId;
  resourceCopy.name += '-copy';
  resourceCopy.name = await GetNextResourceNameAsync(
    resources,
    resourceCopy.name,
    resourceCopy.id
  );

  if (!keepValue) {
    resourceCopy.value += '-copy';
  }

  resources.push(resourceCopy);
  return MergeResources(gameDocument, resources);
};

/**
 * Retrieves all resource entities from the game document.
 * @param gameDocument - The Game Document to check resources
 */
export const GetResources = (gameDocument: GameDocument): ResourceEntity[] => {
  return (gameDocument.resources as ResourceEntity[]) ?? [];
};

/**
 * Retrieves a resource entity from the game document by id.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceEntity = (
  gameDocument: GameDocument,
  resourceId: string
): ResourceEntity => {
  let resources = GetResources(gameDocument);
  let resourceIndex = resources.findIndex((i) => i.id === resourceId)!;
  return resources[resourceIndex];
};

/**
 * Retrieves a resource value from the game document by id.
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The ID of the Resource to update
 */
export const GetResourceValue = (
  gameDocument: GameDocument,
  resourceId: string
) => {
  let resourceEntity = GetResourceEntity(gameDocument, resourceId);
  return resourceEntity?.value ?? '';
};

/**
 * Retrieves resources by ids
 * @param gameDocument - The Game Document to check resources
 * @param ids - The list ID of the resource to get
 * @returns - The list of resources
 */
export const GetResourcesByIds = (
  gameDocument: GameDocument | undefined,
  ids: string[]
) => {
  return (
    gameDocument?.resources?.filter((x) => ids.indexOf(x.id!) !== -1) ?? []
  );
};

/**
 * Retrieves resources by value
 * @param gameDocument - The Game Document to check resources
 * @param value - The value of the resource to get
 * @returns - The list of resources
 */
export const GetResourceByValue = (gameDocument: GameDocument, value: string) =>
  gameDocument.resources.find((resource) => resource.value === value);

/**
 * Retrieves resources by name
 * @param gameDocument - The Game Document to check resources
 * @param name - The name of the resource to get
 * @returns - The list of resources
 */
export const GetResourceByName = (gameDocument: GameDocument, name: string) =>
  gameDocument.resources.find((resource) => resource.name === name);

/**
 * Retrieve resource by id
 * @param gameDocument - The Game Document to check resources
 * @param resourceId - The id of the resource to get
 * @returns
 */
export const GetResourceById = (
  gameDocument: GameDocument,
  resourceId: string
) => gameDocument.resources.find((resource) => resource.id === resourceId);

/**
 * Retrieves all resource keys from the Entity. I.e. *ResId's
 * @param entity - the entity to retrieve Resource
 */
export const GetResourceKeys = (entity: EntityBase) => {
  return (Object.keys(entity) as (keyof typeof entity)[]).filter((key) =>
    key.toString().endsWith('ResId')
  );
};

/**
 * Retrieves all resource keys from the Entity. I.e. *ResId's
 * @param entity - the entity to retrieve Resource
 * @returns all key ended with resid
 */
export const GetEntityResourceKeys = <T>(entity: T): (keyof T)[] => {
  const keyResIds = Object.keys(entity as keyof T).filter((key: string) =>
    key.toLowerCase().endsWith('resid')
  ) as (keyof T)[];
  return keyResIds;
};

/**
 * Convert Resource into ResourceEntity. Task-3954
 * @param resource - resource that want to convert to ResourceEntity
 */
export const ConvertResourceToResourceEntity = (
  resource: Resource
): ResourceEntity => {
  const gameResource: ResourceEntity = {
    id: resource?.id ?? '',
    description: '',
    name: resource?.name ?? '',
    type: resource?.value,
    value: resource?.value,
    size: resource?.size
  };

  return gameResource;
};

/**
 * Retrieve resource by types
 * @param gameDocument - The Game Document to check resources
 * @param types - The resourse types
 * @returns
 */
export const GetResourceByTypes = (
  gameDocument: GameDocument,
  types: ResourceType[]
) =>
  gameDocument.resources.filter((resource) => types.includes(resource.type!));

/**
 * Retrieve max size files based on its source
 * @param imageSource - Source file to get its related max size file
 * @returns
 */
export const GetMaxSizeBySource = (
  imageSource: string | undefined
): number | undefined => {
  switch (imageSource) {
    case 'Game Design | Overview - Banner':
      return 512000; // 500 KB
    case 'Game Design | Overview - Game Icon':
      return 102400; // 100 KB
    case 'Game Design | Overview - Media':
      return 512000; // 500 KB
    case 'Game Design | Maps - Illustration Maps':
      return 5242880; // 5 MB
    case 'Game Design | Maps - Zone - Illustration Maps':
      return 5242880; // 5 MB
    case 'Game Design | Assets - Inventory':
      return 512000; // 500 KB
    case 'Game Design | Assets - Task - Available Icon':
      return 102400; // 100 KB
    case 'Game Design | Assets - Task - Complete Icon':
      return 102400; // 100 KB
    case 'Game Design | Assets - Task Content - HTML':
      return 512000; // 500 KB - currently is set in its respective file because different upload method
    case 'Game Design | Assets - Task Content - Form':
      return 512000; // 500 KB
    case 'Game Design | Assets - Title':
      return 512000; // 500 KB
    case 'Game Design | Assets - Maps':
      return 5242880; // 5 MB
    case 'Game Design | Theme - Logo':
      return 512000; // 500 KB
    case 'Game Design | Theme - Footer':
      return 512000; // 500 KB
    default:
      return undefined;
  }
};

/**
 * Retrieve files extension(s) based on its source
 * @param imageSource - Source file to get its related file extension(s)
 * @returns
 */
export const GetFileExtensionsBySource = (
  imageSource: string | undefined
): string[] | undefined => {
  switch (imageSource) {
    case 'Game Design | Assets - Task Content - Form':
      return ['.png', '.jpg', '.jpeg', '.gif', '.mp4', '.wav', '.mp3'];
    default:
      return undefined;
  }
};

/**
 * Retrieve string conversion from byte formatting
 * @param bytes - Byte number
 * @param decimals - Decimal number
 * @returns
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
