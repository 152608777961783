import cloneDeep from 'lodash.clonedeep';
import { uuid } from '../../../types/common-helper';
import { GameDocument } from '../../../types/game-document';
import {
  ResourceEntity,
  TaskContentEntity
} from '../../../types/game-document/';
import { BodyType } from '../../../types/game-document/body-type';
import {
  AnswerType,
  TaskContentAnswer,
  TaskContentForm
} from '../../../types/game-document/entities/task-content';
import {
  AddResourceAsync,
  AddResourceEntityAsync,
  CopyResourceAsync,
  DeleteResourceAsync,
  GetResourceByName,
  GetResourceEntity,
  GetResourceKeys,
  UpdateResourceAsync
} from '../resources';
import { GetNextAssetNameAsync, MergeAssets } from './index';
/**
 * Adds a new TaskContent to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new TaskContent
 * @param description - The Description for the new TaskContent
 * @returns The updated Game Document
 */
export const AddTaskContentAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  bodyType: BodyType,
  titleResId?: string,
  preMessageResId?: string,
  contentResId?: string,
  id?: string
) => {
  let taskContents = gameDocument?.assets?.taskContents ?? [];
  let taskContentName = await GetNextAssetNameAsync(taskContents, name);
  taskContents.push({
    id: !id ? uuid() : id,
    name: taskContentName,
    description,
    bodyType: bodyType,
    titleResId,
    contentResId,
    preMessageResId
  });
  return MergeAssets(gameDocument, taskContents, 'taskContents');
};

export const AddTaskContentEntityAsync = async (
  gameDocument: GameDocument,
  entity: TaskContentEntity
) => {
  let taskContents = gameDocument.assets.taskContents ?? [];
  let taskContentName = await GetNextAssetNameAsync(
    taskContents,
    entity.name,
    entity.id
  );
  entity.name = taskContentName;
  taskContents.push(entity);
  return MergeAssets(gameDocument, taskContents, 'taskContents');
};

/**
 * Deletes the identified TaskContent from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param taskContentId - The ID of the TaskContent to delete
 * @returns The updated Game Document
 */
export const DeleteTaskContentAsync = async (
  gameDocument: GameDocument,
  taskContentId: string
) => {
  let taskContents = gameDocument.assets.taskContents ?? [];
  let taskContentIndex = taskContents.findIndex((i) => i.id === taskContentId)!;
  let taskContent = taskContents[taskContentIndex];

  if (taskContent) {
    //Copy task content resources
    for (const res of GetResourceKeys(taskContent)) {
      await DeleteResourceAsync(gameDocument, taskContent[res]!);
    }

    taskContent?.forms?.forEach(async (form) => {
      for (const res of GetResourceKeys(form)) {
        await DeleteResourceAsync(gameDocument, form[res]!);
      }

      form?.answers?.forEach(async (answer: TaskContentAnswer) => {
        for (const res of GetResourceKeys(answer)) {
          await DeleteResourceAsync(gameDocument, answer[res]!);
        }
      });
    });
  }

  taskContents.splice(taskContentIndex, 1);
  return MergeAssets(gameDocument, taskContents, 'taskContents');
};

/**
 * Updates the identified TaskContent in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param taskContentId - The ID of the TaskContent to update
 * @param taskContent - The updated TaskContent
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateTaskContentAsync = async (
  gameDocument: GameDocument,
  taskContentId: string,
  taskContent: TaskContentEntity,
  renameDuplicate: boolean = true
) => {
  let taskContents = gameDocument.assets.taskContents ?? [];
  let taskContentIndex = taskContents.findIndex((i) => i.id === taskContentId)!;
  if (renameDuplicate)
    taskContent.name = await GetNextAssetNameAsync(
      taskContents,
      taskContent.name,
      taskContent.id
    );
  taskContents[taskContentIndex] = taskContent;
  return MergeAssets(gameDocument, taskContents, 'taskContents');
};

/**
 * Create a copy of the TaskContent in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param taskContentId - The ID of the TaskContent to copy
 * @returns The updated Game Document
 */
export const CopyTaskContentAsync = async (
  gameDocument: GameDocument,
  taskContentId: string,
  copiedContentId: string = uuid()
) => {
  let gameDoc = gameDocument;

  let taskContents = gameDoc?.assets?.taskContents ?? [];
  let contentIndex = taskContents?.findIndex((i) => i.id === taskContentId)!;
  if (contentIndex !== -1) {
    let contentCopy: TaskContentEntity = cloneDeep(taskContents[contentIndex]);
    contentCopy.titleResId = uuid();
    contentCopy.contentResId = uuid();
    contentCopy.preMessageResId = uuid();

    contentCopy?.forms?.forEach((form) => {
      form.questionResId = uuid();
      form?.answers?.forEach((answer: TaskContentAnswer) => {
        answer.answerResId = uuid();
      });
    });

    //Copy task content resources
    for (const res of GetResourceKeys(contentCopy)) {
      CopyResourceAsync(
        gameDoc,
        taskContents[contentIndex][res]!,
        contentCopy[res]
      ).then((response) => {
        gameDoc = response;
      });
    }

    contentCopy?.forms?.forEach((form, formIndex) => {
      //Copy task content form resources
      for (const res of GetResourceKeys(form)) {
        CopyResourceAsync(
          gameDoc,
          taskContents[contentIndex]?.forms![formIndex][res]!,
          form[res]
        ).then((response) => {
          gameDoc = response;
        });
      }

      let answers: TaskContentAnswer[] = form?.answers;

      answers?.forEach((answer, answerIndex) => {
        //Copy task content form answers
        for (const res of GetResourceKeys(answer)) {
          CopyResourceAsync(
            gameDoc,
            taskContents[contentIndex]?.forms![formIndex]?.answers[answerIndex][
              res
            ]!,
            answer[res]
          ).then((response) => {
            gameDoc = response;
          });
        }
      });
    });

    contentCopy.id = copiedContentId;
    contentCopy.name += '-copy';
    contentCopy.name = await GetNextAssetNameAsync(
      taskContents,
      contentCopy.name,
      contentCopy.id
    );
    taskContents.push(contentCopy);
  }
  return MergeAssets(gameDoc, taskContents, 'taskContents');
};

/**
 * Get task content by Id from game document local storage.
 * @param taskContentId - The taskContentId you want to search
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetTaskContentById = (
  gameDocument: GameDocument | undefined,
  taskContentId: string
) => {
  return gameDocument?.assets?.taskContents?.find(
    (x) => x.id === taskContentId
  );
};

let newResource: ResourceEntity = {
  id: uuid(),
  description: '',
  name: '',
  type: 'text',
  value: ''
};

export const AddNewTaskContentForm = async (
  gameDocument: GameDocument,
  taskContentId: string
) => {
  let taskContent = GetTaskContentById(gameDocument, taskContentId);
  const correctMessageFeedback = {
    isDefaultAllCorrect: true,
    allCorrectMessage: 'Congratulations, you got it!',
    allCorrectUrlResId: '',
    allCorrectMessageResId: uuid()
  };
  const wrongMessageFeedback = {
    isDefaultAllWrong: true,
    allWrongMessage: "That's not the answer!",
    allWrongUrlResId: '',
    allWrongMessageResId: uuid()
  };

  let newForm: TaskContentForm = {
    id: uuid(),
    type: 'text',
    answers: [],
    isManualScoring: false,
    // #6565 fix add default custom feedback when create new question
    customFeedback: {
      isDefaultPartiallyCorrect: true,
      partiallyCorrectMessage: '',
      partiallyCorrectUrlResId: '',
      partiallyCorrectMessageResId: '',
      ...correctMessageFeedback,
      ...wrongMessageFeedback
    }
  };

  //Add new Question resource to local storage
  newResource.id = uuid();
  await AddResourceEntityAsync(gameDocument, newResource);

  newForm.questionResId = newResource.id;

  if (!taskContent?.forms) {
    taskContent!.forms = [];
  }

  if (!newForm.answers) {
    newForm.answers = [];
  }

  //Add default answer resource to local storage
  newResource.id = uuid();
  await AddResourceEntityAsync(gameDocument, newResource);
  newForm?.answers?.push({
    id: uuid(),
    answerResId: newResource.id
  });

  //Add default correct feedback resource
  await AddResourceEntityAsync(gameDocument, {
    id: correctMessageFeedback.allCorrectMessageResId,
    description: '',
    name: 'All correct feedback message default',
    type: 'text',
    value: ''
  });

  // Add default wrong feedback resource
  await AddResourceEntityAsync(gameDocument, {
    id: wrongMessageFeedback.allWrongMessageResId,
    description: '',
    name: 'All wrong feedback message default',
    type: 'text',
    value: ''
  });

  taskContent?.forms?.push(newForm);

  return await UpdateTaskContentAsync(
    gameDocument,
    taskContentId,
    taskContent!
  );
};
export const AddAnswerTaskContentForm = async (
  gameDocument: GameDocument,
  taskContentId: string,
  questionResId: string
) => {
  let taskContent = GetTaskContentById(gameDocument, taskContentId);

  const questionIndex = taskContent?.forms?.findIndex(
    (x) => x.questionResId === questionResId
  );

  if (taskContent?.forms && questionIndex! > -1) {
    //Add answer resource to local storage
    newResource.id = uuid();
    await AddResourceEntityAsync(gameDocument, newResource);

    if (!taskContent?.forms![questionIndex!]?.answers) {
      taskContent.forms[questionIndex!].answers = [];
    }

    taskContent?.forms![questionIndex!]?.answers.push({
      answerResId: newResource.id
    });

    return await UpdateTaskContentAsync(
      gameDocument,
      taskContentId,
      taskContent!
    );
  }
};
export const UpdateTaskContentFormAnswers = async (
  gameDocument: GameDocument,
  taskContentId: string,
  formId: string,
  answers: TaskContentAnswer[]
) => {
  let newGameDocument: GameDocument = cloneDeep(gameDocument);
  let taskContent = GetTaskContentById(
    cloneDeep(newGameDocument),
    taskContentId
  );

  let formIndex = taskContent?.forms?.findIndex((x) => x.id === formId);

  taskContent!.forms![formIndex!].answers = answers;

  answers.forEach(async (answer) => {
    let resource = GetResourceEntity(newGameDocument, answer.answerResId);
    resource.value = answer.description;

    newGameDocument = await UpdateResourceAsync(
      newGameDocument,
      resource.id,
      resource
    );
  });

  return await UpdateTaskContentAsync(
    newGameDocument,
    taskContentId,
    taskContent!
  );
};
export const DeleteAnswerTaskContentForm = async (
  gameDocument: GameDocument,
  taskContentId: string,
  questionResId: string,
  answerResId: string
) => {
  let taskContent = GetTaskContentById(gameDocument, taskContentId);

  const questionIndex = taskContent?.forms?.findIndex(
    (x) => x.questionResId === questionResId
  );

  let form = taskContent!.forms![questionIndex!] as TaskContentForm;

  if (form && questionIndex! > -1) {
    const answerIndex = form.answers?.findIndex(
      (x) => x.answerResId === answerResId
    );

    DeleteResourceAsync(
      gameDocument,
      form.answers![answerIndex!].answerResId
    ).then(async (response) => {
      taskContent?.forms![questionIndex!]?.answers?.splice(answerIndex!, 1);

      return await UpdateTaskContentAsync(
        gameDocument,
        taskContentId,
        taskContent!
      );
    });
  }

  return await UpdateTaskContentAsync(
    gameDocument,
    taskContentId,
    taskContent!
  );
};
export const CopyTaskContentFormQuestion = async (
  gameDocument: GameDocument,
  taskContentId: string,
  questionId: string
) => {
  let taskContent = GetTaskContentById(gameDocument, taskContentId);

  const baseFormCopy = taskContent?.forms?.find(
    (x) => x.questionResId === questionId
  ) as TaskContentForm;

  let formToCopy = {
    ...baseFormCopy,
    id: uuid(),
    answers: baseFormCopy.answers?.map((answer) => {
      let newAnswer = { ...answer };
      newAnswer.id = uuid();
      return newAnswer;
    })
  } as TaskContentForm;

  if (formToCopy?.questionResId) {
    const newQuestionResId = uuid();
    CopyResourceAsync(
      gameDocument,
      formToCopy?.questionResId,
      newQuestionResId
    ).then((response) => {
      formToCopy.questionResId = newQuestionResId;
    });
  }

  formToCopy?.answers?.forEach(async (opt) => {
    if (opt.answerResId) {
      const newOptionsResId = uuid();
      CopyResourceAsync(gameDocument, opt.answerResId, newOptionsResId).then(
        (response) => {
          opt.answerResId = newOptionsResId;
        }
      );
    }
  });

  taskContent?.forms?.push(formToCopy!);

  return await UpdateTaskContentAsync(
    gameDocument,
    taskContentId,
    taskContent!
  );
};
export const DeleteTaskContentForm = async (
  gameDocument: GameDocument,
  taskContentId: string,
  questionId: string
) => {
  let taskContent = GetTaskContentById(gameDocument, taskContentId);

  const questionIndex = taskContent?.forms?.findIndex(
    (x) => x.questionResId === questionId
  );

  if (taskContent!.forms && questionIndex! > -1) {
    const question = taskContent!.forms[questionIndex!];

    const resIds = GetQuestionResourceIds(question);

    resIds.forEach(async (id) => {
      await DeleteResourceAsync(gameDocument, id);
    });

    taskContent?.forms?.splice(questionIndex!, 1);

    return await UpdateTaskContentAsync(
      gameDocument,
      taskContentId,
      taskContent!
    );
  }
};
export const GetQuestionResourceIds = (question: TaskContentForm) => {
  let ids: string[] = [];

  if (question.questionResId) {
    ids.push(question.questionResId);
  }

  if (question.answers) {
    question.answers?.forEach((opt) => {
      if (opt.answerResId) {
        ids.push(opt.answerResId);
      }
    });
  }

  if (question.customFeedback) {
    ids.push(question.customFeedback.allCorrectMessageResId!);
    ids.push(question.customFeedback.partiallyCorrectMessageResId!);
    ids.push(question.customFeedback.allWrongMessageResId!);
  }

  return ids;
};
export const AddDefaultAnswers = (type: AnswerType) => {
  if (type === 'none') return [];

  let newAnswerResources: ResourceEntity[] = [];

  newAnswerResources.push({
    id: uuid(),
    name: '',
    description: '',
    type: type === 'textarea' ? 'text-long' : 'text',
    value: type === 'time' ? '00:00' : '' // If type === 'time', set default value to 00:00
  });

  if (type === 'checkbox' || type === 'radio') {
    newAnswerResources.push({
      id: uuid(),
      name: '',
      description: '',
      type: 'text',
      value: ''
    });
  }

  return newAnswerResources;
};
export const GetTaskContents = (gameDocument: GameDocument) => {
  return gameDocument.assets.taskContents ?? [];
};

/**
 * Create resource for default all correct custom feedback, if it isn't exist, and return the refId
 * @returns
 */
export const AllCorrectCustomFeedback = async (
  gameDocument: GameDocument,
  message: string,
  id: string = uuid()
) => {
  let allCorrectResource = await GetResourceByName(
    gameDocument!,
    'All correct feedback message default'
  );

  if (!allCorrectResource) {
    await AddResourceAsync(
      gameDocument!,
      'All correct feedback message default',
      '',
      'text',
      message,
      id
    ).then((response) => {
      let responseValue = response.resources.find(
        (item) => item.name === 'All correct feedback message default'
      );
      return responseValue?.value ?? '';
    });
  } else {
    return allCorrectResource.value ?? '';
  }

  return '';
};

/**
 * Create resource for default partial correct custom feedback, if it isn't exist, and return the refId
 * @returns
 */
export const PartialCorrectCustomFeedback = async (
  gameDocument: GameDocument
) => {
  let allCorrectResource = await GetResourceByName(
    gameDocument!,
    'Partial correct feedback message default'
  );

  if (!allCorrectResource) {
    await AddResourceAsync(
      gameDocument!,
      'Partial correct feedback message default',
      '',
      'text',
      'That answer was partially correct! Partial points awarded.',
      uuid()
    ).then((response) => {
      let responseValue = response.resources.find(
        (item) => item.name === 'Partial correct feedback message default'
      );
      return responseValue?.value ?? '';
    });
  } else {
    return allCorrectResource.value ?? '';
  }

  return '';
};

/**
 * Create resource for default all wrong custom feedback, if it isn't exist, and return the refId
 * @returns
 */
export const AllWrongCustomFeedback = async (
  gameDocument: GameDocument,
  message: string,
  id: string = uuid()
) => {
  let allCorrectResource = await GetResourceByName(
    gameDocument!,
    'All wrong feedback message default'
  );

  if (!allCorrectResource) {
    await AddResourceAsync(
      gameDocument!,
      'All wrong feedback message default',
      '',
      'text',
      message,
      id
    ).then((response) => {
      let responseValue = response.resources.find(
        (item) => item.name === 'All wrong feedback message default'
      );
      return responseValue?.value ?? '';
    });
  } else {
    return allCorrectResource.value ?? '';
  }
  return '';
};

/**
 * Get default value for custom feedback
 * @returns
 */
export const DefaultCustomFeedback = () => {
  return {
    isDefaultAllCorrect: true,
    allCorrectMessage: '',
    allCorrectUrlResId: '',
    allCorrectMessageResId: '',
    isDefaultPartiallyCorrect: true,
    partiallyCorrectMessage: '',
    partiallyCorrectUrlResId: '',
    partiallyCorrectMessageResId: '',
    isDefaultAllWrong: true,
    allWrongMessage: '',
    allWrongUrlResId: '',
    allWrongMessageResId: ''
  };
};
