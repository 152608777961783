import { GameDocument } from '../../../types/game-document';
import { ItemEntity } from '../../../types/game-document/';
import { uuid } from '../../../types/common-helper';
import { GetNextAssetNameAsync, MergeAssets } from './index';
import {
  CopyResourceAsync,
  DeleteResourceAsync,
  GetResourceKeys
} from '../resources';

/**
 * Adds a new Item to the Game document.
 * @param gameDocument - The Game Document to modify
 * @param name - The Name of the new Item
 * @param description - The Description for the new Item
 * @returns The updated Game Document
 */
export const AddItemAsync = async (
  gameDocument: GameDocument,
  name: string,
  description: string,
  titleResId: string,
  summaryResId: string,
  imageResId: string
) => {
  let items = gameDocument.assets.items ?? [];
  let itemName = await GetNextAssetNameAsync(items, name);
  items.push({
    id: uuid(),
    name: itemName,
    description,
    titleResId,
    summaryResId,
    imageResId
  });
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Deletes the identified Item from the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to delete
 * @returns The updated Game Document
 */
export const DeleteItemAsync = async (
  gameDocument: GameDocument,
  itemId: string
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (itemIndex !== -1) {
    for (const res of GetResourceKeys(items[itemIndex])) {
      await DeleteResourceAsync(gameDocument, items[itemIndex][res]!);
    }
    items.splice(itemIndex, 1);
  }
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Updates the identified Item in the Game Document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to update
 * @param item - The updated Item
 * @param renameDuplicate - Auto rename 'name' key if duplicate
 * @constructor
 */
export const UpdateItemAsync = async (
  gameDocument: GameDocument,
  itemId: string,
  item: ItemEntity,
  renameDuplicate: boolean = true
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (renameDuplicate)
    item.name = await GetNextAssetNameAsync(items, item.name, item.id);
  items[itemIndex] = item;
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Create a copy of the Item in the Game document.
 * @param gameDocument - The Game Document to modify
 * @param itemId - The ID of the Item to copy
 * @param copiedItemId - The new ID of the copied Item
 * @returns The updated Game Document
 */
export const CopyItemAsync = async (
  gameDocument: GameDocument,
  itemId: string,
  copiedItemId: string = uuid()
) => {
  let items = gameDocument.assets.items ?? [];
  let itemIndex = items.findIndex((i) => i.id === itemId)!;
  if (itemIndex !== -1) {
    let itemCopy: ItemEntity = {
      ...items[itemIndex],
      titleResId: uuid(),
      summaryResId: uuid(),
      imageResId: uuid()
    };
    for (const res of GetResourceKeys(itemCopy)) {
      await CopyResourceAsync(
        gameDocument,
        items[itemIndex][res]!,
        itemCopy[res]
      );
    }
    itemCopy.id = copiedItemId;
    itemCopy.name += '-copy';
    itemCopy.name = await GetNextAssetNameAsync(
      items,
      itemCopy.name,
      itemCopy.id
    );
    items.push(itemCopy);
  }
  return MergeAssets(gameDocument, items, 'items');
};

/**
 * Get all items from game document local storage.
 * @param gameDocument - The Game Document as datasource
 * @constructor
 */
export const GetItems = (gameDocument: GameDocument | undefined) => {
  return gameDocument?.assets?.items ?? [];
};
