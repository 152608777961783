import { formatDate } from '@progress/kendo-intl';

export const getFormattedDateString = (date: Date) => {
  return formatDate(date, 'g');
};

export const convertJsonDateToDate = (jsonDate: Date | undefined) => {
  if (jsonDate) {
    return new Date(JSON.parse(JSON.stringify(jsonDate)));
  }
};

export const getTime = (date: Date): string => {
  const result = `${date.toLocaleString(undefined, {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  })}`;
  return result;
};

export const getTimePickerFormat = (time: string | undefined): Date => {
  if (!time) return new Date();
  const timeDate = Date.parse(time);
  let date: Date;
  if (!isNaN(timeDate)) {
    date = new Date(time);
    date.setHours(date.getHours(), date.getMinutes());
  } else {
    const [hour, minute] = time.split(':');
    date = new Date();
    date.setHours(parseInt(hour), parseInt(minute));
  }
  return date;
};

export const getTotalMinutes = (startDateUtc: Date, endDateUtc: Date) => {
  let difference = endDateUtc.getTime() - startDateUtc.getTime(); // This will give difference in milliseconds
  return Math.round(difference / 60000);
};

export const formatDateToDDMMYYYY = (
  date: Date | string,
  includeTime = false
) => {
  if (!date) return '';
  const convertDate = new Date(date);

  let day: string | number = convertDate.getDate();
  let month: string | number = convertDate.getMonth() + 1;
  let year = convertDate.getFullYear();

  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  let formattedDate = `${day}/${month}/${year}`;

  if (includeTime) {
    let hours: string | number = convertDate.getHours();
    let minutes: string | number = convertDate.getMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;

    if (hours < 10) hours = '0' + hours;

    if (minutes < 10) minutes = '0' + minutes;

    let formattedTime = `${hours}:${minutes} ${ampm}`;
    formattedDate += ` ${formattedTime}`;
  }

  return formattedDate;
};
