import SelectionItem from '../types/selection-item';

export const Identifiers: SelectionItem[] = [
  {
    id: '1',
    name: 'player',
    description: 'the current player.',
    colorClass: 'primary'
  },
  {
    id: '2',
    name: 'team',
    description: "the current player's team.",
    colorClass: 'info'
  },
  {
    id: '3',
    name: 'score',
    description: 'the task score.',
    colorClass: 'info',
    isShowOnConditionalOnly: true,
    availableOnActions: ['Task.onComplete']
  }
];

export default Identifiers;
